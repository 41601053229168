import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SquareIframeHomepage = () => {
    const [adBlockEnabled, setAdBlockEnabled] = useState(false);
    useEffect(() => {
        const detectAdBlock = async () => {
            axios
                .get(
                    'https://adserver.gesundheitsticket.de/revive/www/delivery/afr.php?zoneid=8&amp;cb=23423'
                )
                .then((response) => {})
                .catch((error) => {
                    setAdBlockEnabled(true);
                });
        };

        detectAdBlock();
    }, []);

    if (adBlockEnabled) {
        return null; // Don't render anything if ads are blocked
    }

    return (
        <div className="banner2 border-radious5 banner_homepage mb20 mt20 mt-lg-0">
            <iframe
                title="homepage-square"
                id="ade584b3"
                name="ade584b3"
                src="https://adserver.gesundheitsticket.de/revive/www/delivery/afr.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE"
                frameBorder="0"
                scrolling="no"
                width="308"
                height="257"
                allow="autoplay"
            >
                <a
                    href="https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a9162331&amp;cb=INSERT_RANDOM_NUMBER_HERE"
                    target="_blank"
                >
                    <img
                        src="https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a9162331"
                        border="0"
                        alt=""
                    />
                </a>
            </iframe>
        </div>
    );
};

export default SquareIframeHomepage;

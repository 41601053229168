import axios from 'axios';
import React, { useEffect, useState } from 'react';

const HorizontalIframeHomepage = () => {
    const [bannerOpacity, setBannerOpacity] = useState(0);
    const [showBanner, setShowBanner] = useState(false);
    const [adBlockEnabled, setAdBlockEnabled] = useState(false);
    const [activeBanner, setActiveBanner] = useState(0);
    const [bannerSrc, setBannerSrc] = useState(
        'https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a2b09022'
    );
    const [bannerLink, setBannerLink] = useState(
        'https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a2b09022&amp;cb=INSERT_RANDOM_NUMBER_HERE'
    );

    useEffect(() => {
        const detectAdBlock = async () => {
            axios
                .get(
                    'https://adserver.gesundheitsticket.de/revive/www/delivery/afr.php?zoneid=8&amp;cb=23423'
                )
                .then((response) => {})
                .catch((error) => {
                    setAdBlockEnabled(true);
                });
        };

        detectAdBlock();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Generate a new random number for cache busting
            const randomNumber = Math.floor(Math.random() * 1000000);
            const newBannerSrc = `https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=8&amp;cb=${randomNumber}&amp;n=a2b09022`;
            const newBannerLink = `https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a2b09022&amp;cb=${randomNumber}`;

            // setBannerSrc(newBannerSrc);
            // setBannerLink(newBannerLink);
            setShowBanner(false); // Start the transition by hiding the current banner

            // Wait for the transition to complete before updating the banner source and link
            // setTimeout(() => {
            setBannerSrc(newBannerSrc);
            setBannerLink(newBannerLink);
            // setShowBanner(true); // Show the new banner after the transition
            // }, 500);
        }, 9000); // Change the time interval here (in milliseconds) for how often the banner should be updated

        return () => clearTimeout(timer);
    }, [bannerSrc, bannerLink]);

    const banners = [
        {
            id: 1,
            src:
                'https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=8&amp;cb=12345&amp;n=a2b09022',
            link:
                'https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a2b09022&amp;cb=12345',
        },
        {
            id: 2,
            src:
                'https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=8&amp;cb=67890&amp;n=a2b09022',
            link:
                'https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a2b09022&amp;cb=67890',
        },
        // Add more banner objects as needed
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveBanner((prevBanner) =>
                prevBanner === banners.length - 1 ? 0 : prevBanner + 1
            );
        }, 5000); // Change the time interval here (in milliseconds) for how often the banner should be updated

        return () => clearInterval(interval);
    }, []);

    if (adBlockEnabled) {
        return null; // Don't render anything if ads are blocked
    }

    // return (
    //     <div
    //         id="carouselExampleSlidesOnly"
    //         className="carousel slide"
    //         data-ride="carousel"
    //     >
    //         <div className="carousel-inner">
    //             {banners.map((banner, index) => (
    //                 <div
    //                     className={`carousel-item ${
    //                         index === activeBanner ? 'active' : ''
    //                     }`}
    //                     key={banner.id}
    //                 >
    //                     <a
    //                         href={banner.link}
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                     >
    //                         <img
    //                             className="d-block w-100"
    //                             src={banner.src}
    //                             alt={`Banner ${banner.id}`}
    //                         />
    //                     </a>
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );

    return (
        <div
            id="ad"
            className="banner2 border-radious5 banner_horizontal mb20 mt20"
            // style={{ transition: 'opacity 0.5s', opacity: showBanner ? 1 : 0 }}
        >
            <iframe
                title="homepage-horizontal"
                id="ac108c94"
                name="ac108c94"
                src={`https://adserver.gesundheitsticket.de/revive/www/delivery/afr.php?zoneid=8&amp;cb=${Math.floor(
                    Math.random() * 1000000
                )}`}
                frameBorder="0"
                scrolling="no"
                width="892"
                height="110"
                allow="autoplay"
            >
                <a href={bannerLink} target="_blank">
                    <img src={bannerSrc} border="0" alt="" />
                </a>
            </iframe>
        </div>
    );
};

// const HorizontalIframeHomepage = () => {
//     return (
//         <div className="banner2 border-radious5 banner_horizontal mb20 mt20">
//             <iframe
//                 title="homepage-horizontal"
//                 id="ac108c94"
//                 name="ac108c94"
//                 src="https://adserver.gesundheitsticket.de/revive/www/delivery/afr.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE"
//                 frameBorder="0"
//                 scrolling="no"
//                 width="892"
//                 height="110"
//                 allow="autoplay"
//             >
//                 <a
//                     href="https://adserver.gesundheitsticket.de/revive/www/delivery/ck.php?n=a2b09022&amp;cb=INSERT_RANDOM_NUMBER_HERE"
//                     target="_blank"
//                 >
//                     <img
//                         src="https://adserver.gesundheitsticket.de/revive/www/delivery/avw.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a2b09022"
//                         border="0"
//                         alt=""
//                     />
//                 </a>
//             </iframe>
//         </div>
//     );
// };

export default HorizontalIframeHomepage;
